import React from 'react';
import styled from 'styled-components';

const Name = styled.div`
  font-weight: 600;
  font-size: 1rem;
`;

const List = styled.div`
  font-weight: 200;
  font-size: 0.9rem;
  margin-left: 5px;
  flex-grow: 1;
`;

const Container = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

interface OneLineListProps {
  name: string;
  items: string[];
}

const OneLineList: React.FC<OneLineListProps> = ({ name, items }) => {
  return (
    <Container>
      <Name>{name}:
      <List>
        <div>{items.join(' / ')}</div>
      </List>
      </Name>
    </Container>
  );
};

export default OneLineList;