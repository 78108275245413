import React from 'react';


const AboutUs = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="py-8">
        <h1 className="text-3xl font-bold mb-2">About Us</h1>
        <p className="text-gray-600 text-sm leading-relaxed">
        At ResumeTitan, we're dedicated to simplifying the resume creation process and enhancing your career readiness. Leveraging cutting-edge technology like GenAi, we provide a comprehensive suite of tools to streamline resume writing, cover letter composition, and interview preparation. With GenAi, we infuse your resumes with compelling phrases and sought-after skills, tailored to impress employers. Continuously gathering feedback from diverse organizations, we understand the importance of AI in modern job searches and strive to harness its power to expedite and elevate the resume creation journey. Whether you're a seasoned professional or a recent graduate, let ResumeTitan be your ally in landing your dream job faster.
        </p>
        
      </div>




      <div className="py-8">
        <h1 className="text-3xl font-bold mb-2">The Founders Mission</h1>
        <p className="text-gray-600 text-sm leading-relaxed">
        At ResumeTitan, our mission is clear: to accelerate the resume creation process. Drawing from our experience as former hiring managers, we recognize the significance of delivering quality, job-specific information to employers. Our tool simplifies template selection, customizes each resume, and provides a scoring mechanism to optimize your chances of securing interviews. Having worked with individuals at all career stages, including GED students and professionals at Fortune 500 companies, we're committed to offering a solution accessible to everyone. In today's competitive job market, where uncertainties abound, maintaining an updated resume is crucial. Our aim is to empower our users with a competitive edge during the early stages of their job search journey.
        </p>
        
      </div>


      <div className="py-8">
        <h1 className="text-3xl font-bold mb-2">Success Stories</h1>
        <p className="text-gray-600 text-sm leading-relaxed">
        Collaborating with students at Methodist University, ResumeTitan conducted a user test involving 30 students, revealing insightful feedback. Surprisingly, 65% of participants weren't utilizing any resume creation tool prior to our solution. Impressively, 80% rated our solution with 4 or 5 stars on a scale of 1-5, showcasing its effectiveness and user satisfaction. Notably, 90% found our solution easy to navigate, while an overwhelming 95% expressed willingness to continue using it beyond the test environment. Moreover, students displayed a positive outlook on the integration of AI into society. Leveraging this feedback, ResumeTitan remains committed to refining and enhancing our tool, ensuring an optimal user experience for students navigating their career paths.
        </p>
        
      </div>

    </div>
  );
};

export default AboutUs;
