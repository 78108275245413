import React from 'react';
import About from './About';
import Hero from './Hero';
import IntroPage from './IntroPage';
// import Logos from './Logos/Logos';
import Pricing from 'components/Pricing';
import 'styles/index.css';

const LandingPage: React.FC = () => {
  return (
    <div className="bg-white overflow-x-hidden">
      <IntroPage />
      <Hero />
      {/* <Logos /> */}
      <About />
      <div data-aos="fade-right">
        <Pricing />
      </div>
      {/* <Blogs /> */}
    </div>
  );
};

export default LandingPage;
