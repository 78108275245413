import React from 'react';

export const TermsOfService = () => {
  return (
    <div className="relative w-full max-w-screen-lg p-4 text-black">
      <div className="relative rounded-lg">
        <div className="p-6 space-y-6">
          <p className="text-base font-bold leading-relaxed">
            Welcome to ResumeTitan!
          </p>
          <p className="text-base leading-relaxed">
            These Terms of Service ("Terms") govern your use of ResumeTitan operated by ResumeTitan, LLC. By accessing or using ResumeTitan, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use ResumeTitan.
          </p>

          <p className="text-base font-bold leading-relaxed">
          1. Use of the Website
          </p>
          <p className="text-base leading-relaxed">
            (a) You must be at least 18 years old to use the Website.
          </p>
          <p className="text-base leading-relaxed">
            (b) You agree to provide accurate and complete information when using the Website.
          </p>
          <p className="text-base leading-relaxed">
            (c) You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
          </p>

          <p className="text-base font-bold leading-relaxed">
          2. Data Storage
          </p>
          <p className="text-base leading-relaxed">
            (a) The Website may collect and store information provided by users. This information may include, but is not limited to, personal details, preferences, and usage data.
          </p>
          <p className="text-base leading-relaxed">
            (b) We take reasonable measures to protect your data, but we cannot guarantee absolute security.
          </p>
          <p className="text-base leading-relaxed">
            (c) By using the Website, you consent to the collection, storage, and processing of your information as described in our Privacy Policy.
          </p>

          <p className="text-base font-bold leading-relaxed">
          3. External API
          </p>
          <p className="text-base leading-relaxed">
            (a) The Website may interact with external APIs to provide certain features or services.
          </p>
          <p className="text-base leading-relaxed">
            (b) Your use of the Website constitutes consent to share necessary information with external APIs in accordance with our Privacy Policy.
          </p>
          <p className="text-base font-bold leading-relaxed">
          4. Intellectual Property
          </p>
          <p className="text-base leading-relaxed">
            (a) The Website and its contents are protected by intellectual property laws. You may not use, reproduce, or distribute any part of the Website without our express written consent.
          </p>
          <p className="text-base font-bold leading-relaxed">
          5. Limitation of Liability
          </p>
          <p className="text-base leading-relaxed">
            (a) We are not liable for any damages or losses resulting from your use of the Website.
          </p>
          <p className="text-base font-bold leading-relaxed">
          6. Personal Information
          </p>
          <p className="text-base leading-relaxed">
            (a) Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy, please see our <a className="underline" href="/privacy">Privacy Policy</a>.
          </p>
          <p className="text-base font-bold leading-relaxed">
          7. Termination
          </p>
          <p className="text-base leading-relaxed">
            (a) We may terminate or suspend your access to the Website at any time without notice for any reason.
          </p>
          <p className="text-base font-bold leading-relaxed">
          8. Changes to Terms
          </p>
          <p className="text-base leading-relaxed">
            (a) We reserve the right to modify these Terms at any time. The updated version will be effective upon posting on the Website.
          </p>
          <p className="text-base font-bold leading-relaxed">
          9. Governing Law
          </p>
          <p className="text-base leading-relaxed">
            (a) These Terms are governed by the laws of the United States of America.
          </p>
          <p className="text-base font-bold leading-relaxed">
          10. Contact Us
          </p>
          <p className="text-base leading-relaxed">
            (a) If you have any questions or concerns about these Terms, please contact us at info@resumetitan.com.
          </p>
          <p className="text-base font-bold leading-relaxed">

          </p>
        </div>
      </div>
    </div>
  )
}