import React from 'react';

const ContactPage = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="py-8">
        <h1 className="text-3xl font-bold mb-2">Contact Us</h1>
        <p className="text-gray-600 text-sm">For any questions, please contact info@resumetitan.com</p>
      </div>

    </div>
  );
};

export default ContactPage;
