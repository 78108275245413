import React from 'react';
import Pricing from 'components/Pricing';

const PricingPage: React.FC = () => {
  return (
    <div className="mb-10">
      <Pricing />
    </div>
  );
};

export default PricingPage;